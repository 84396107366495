<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('huaz.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background-color: #181e34;">
      <van-form>
		  <van-row>
			  <div class="lable_title">{{$t('huaz.out')}}</div>
			  <div class="radio_wrap">
			  	<div class="radio_item" 
			  		:class="{active_radio:from==item.value}" 
			  		v-for="(item,index) in fromoptions[langindex]"
			  		@click="selzhuanchu(item,index)">{{item.text}}</div>
			  </div>
			  <div class="lable_title">{{$t('huaz.in')}}</div>
			  <div class="radio_wrap">
			  	<div class="radio_item" 
			  		:class="{active_radio:to==item.value}" 
			  		v-for="(item,index) in tooptions[langindex]"
			  		@click="selzhuanru(item,index)">{{item.text}}</div>
			  </div>
			  <div class="lable_title">{{$t('huaz.biz')}}</div>
			  <div class="radio_wrap">
				<div class="radio_item" 
					:class="{active_radio:bzvalue==item.value}" 
					v-for="(item,index) in bizhong">{{item.text}}</div>
			  </div>
			<div class="form_cell_item">
			  <div class="form_cell_label">{{$t('tix.number')}}</div>
			  <input :placeholder="$t('tix.enter')" v-model="value"/>
			</div>
			<div
			  style="width: 100%; text-align: left; padding-left: 13px; margin: 12px 0px;color: #8686aa;"
			  v-for="(item, index) in balance"
			  :key="item.id"
			>
			  {{ fromoptions[langindex][index].text }}{{ $t('huaz.text')
			  }}{{ item.value }}
			</div>
		  </van-row>
        <van-row v-if="false">
          <van-cell :title="$t('huaz.out')" center>
            <template #icon>
              <span class="dot_green"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item
                  v-model="from"
                  :options="fromoptions[langindex]"
                  @change="change"
                />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-cell :title="$t('huaz.in')" center>
            <template #icon>
              <span class="dot_green"></span>
            </template>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item
                  v-model="to"
                  :options="tooptions[langindex]"
                />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <div
            style="width: 100%; text-align: left; padding-left: 13px; margin: 12px 0px;"
            v-for="(item, index) in balance"
            :key="item.id"
          >
            {{ fromoptions[langindex][index].text }}{{ $t('huaz.text')
            }}{{ item.value }}
          </div>

          <van-cell :title="$t('huaz.biz')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bzvalue" :options="bizhong" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-field
            :label="$t('huaz.number')"
            :placeholder="$t('huaz.enter')"
            v-model="value"
            clearable
            ><template #button>
              <van-button size="small" type="primary" @click.prevent="all">{{
                $t('huaz.allbtn')
              }}</van-button>
            </template>
          </van-field>
        </van-row>
        <van-button class="submitBtns" @click.prevent="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      from: 1,
      fromoptions: [
        [
          { text: '币币账户', value: 1 },
          { text: '法币账户', value: 2 },
          { text: '创新', value: 3 },
          { text: 'DeFi', value: 4 }
        ],
        [
          { text: 'Exchange account', value: 1 },
          { text: 'Fiat account', value: 2 },
          { text: 'Innovate', value: 3 },
          { text: 'DeFi', value: 4 }
        ]
      ],
      to: 2,
      tooptions: [
        [
          { text: '法币账户', value: 2 },
          { text: '创新', value: 3 },
          { text: 'DeFi', value: 4 }
        ],
        [
          { text: 'Fiat account', value: 2 },
          { text: 'Innovate', value: 3 },
          { text: 'DeFi', value: 4 }
        ]
      ],
      bzvalue: 0,
      bizhong: [{ text: 'USDT', value: 0 }],
      // 划转数量
      value: '',
      balance: [],
      lang: localStorage.getItem('lang') || 'zh',
      langindex: localStorage.getItem('lang') === 'zh' ? 0 : 1
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/user/transfer')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data
        }
      }
    },
	selzhuanchu(item,index){
		this.from=item.value;
		this.change();
	},
	selzhuanru(item,index){
		this.to=item.value;
	},
    change() {
      this.value = ''
      this.tooptions[this.langindex] = []
      this.fromoptions[this.langindex].forEach(item => {
        if (item.value !== this.from) {
          this.tooptions[this.langindex].push(item)
        }
      })
      this.to = this.tooptions[this.langindex][0].value
    },
    all() {
      this.value = this.balance[this.from - 1].value
    },
    async submit() {
		if(this.value==''){
			return;
		}
      const postform = {
        from: this.balance[this.from - 1].name,
        to: this.balance[this.to - 1].name,
        number: this.value
      }
      const { data } = await this.$http.post('/home/user/transfer', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          this.$router.push('/assets')
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
	.form_cell_item{
		padding: 0.6rem;
		display: flex;
		align-items: center;
		position: relative;
		&::after {
		    content: " ";
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 0.026667rem;
		    border-bottom: 0.026667rem solid #e5e5e5;
		    color: #e5e5e5;
		    -webkit-transform-origin: 0 100%;
		    -ms-transform-origin: 0 100%;
		    transform-origin: 0 100%;
		    -webkit-transform: scaleY(0.5);
		    -ms-transform: scaleY(0.5);
		    transform: scaleY(0.5);
		    z-index: 2;
		}
		.form_cell_label{
			width: 115px;
			font-size: 18px;
			color: #8686aa;
			text-align: left;
		}
		input{
			width: 100%;
			flex: 1;
			padding: 20px;
			box-sizing: border-box;
			background-color: transparent;
			color: #8686aa;
			outline: none;
			font-size: 18px;
			border: none;
		}
	}
	.form_item{
		padding: 0.6rem;
		input{
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #ff571e;
			border-radius: 0.4rem;
			background-color: transparent;
			    color: #ff571e;
		}
	}
	.lable_title{
		font-size: 1rem;
		text-align: left;
		padding: 0.6rem;
		color: #cad0e6;
	}
	.radio_wrap{
		display: flex;
		padding: 0.6rem;
		.radio_item{
			width: 22%;
			margin-right: 10px;
			padding: 12px 0;
			text-align: center;
			border-radius: 0.4rem;
			color: #ff571e;
			border: 1px solid #ff571e;
			font-size: 0.8rem;
		}
		.active_radio{
			background: #ff571e;
			color: #cad0e6;
		}
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.98667rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
    .dot_green {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #004ea3;
      margin-right: 8px;
      display: inline-block;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.98667rem;
    /deep/.van-field__label {
      color: #323232;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .submitBtns {
    margin-top: 30px;
    width: 90%;
    letter-spacing: 0.53333rem;
    height: 3rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #ff571e;
    border-radius: 0.23333rem;
    border: none;
  }
}
</style>
